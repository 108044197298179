
import { defineComponent, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import InputSwitch from "primevue/inputswitch";
import * as Catalog from "../api/helpers/Catalog";
import Spinner from "../components/Spinner.vue";
import Checkbox from "primevue/checkbox";
import _ from "underscore";

export default defineComponent({
  name: "TypeSwitch",
  components: {
    InputSwitch,
    Spinner,
    Checkbox,
  },
  props: {
    label: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
    catalog: String,
    origin: String,
  },
  data() {
    return {
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_COLOR,
    };
  },
  setup(props) {
    var loading = ref(true);
    const catalogData = ref({});
    const f = reactive({
      name: false,
    });
    const getCatalog = async () => {
      await Catalog._find(
        `/${props.catalog}?system=${process.env.VUE_APP_SYSTEM_ID}&order=id`
      ).then((res) => {
        catalogData.value = res.data.data;
        loading.value = false;
      });
    };
    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { required },
        })
      : (rules = {
          name: {},
        });

    if (props.isRequired) rules.name.required = required;

    const v$ = useVuelidate(rules, f);
    const selectedCatalog = ref([{ id: 0 }]);
    const selectedCatalogCheck = ref([{ id: 0 }]);
    selectedCatalog.value.shift();
    selectedCatalogCheck.value.shift();
    return {
      f,
      v$,
      getCatalog,
      catalogData,
      loading,
      selectedCatalog,
      selectedCatalogCheck,
    };
  },
  mounted() {
    if (this.catalog) this.getCatalog();
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      sessionStorage.getItem("steps") &&
      typeof step === "object" &&
      this.answerKey
    ) {
      if (step[this.answerKey]) {
        if (_.isArray(step[this.answerKey]))
          this.selectedCatalog = step[this.answerKey] || [];
        else this.v$.name.$model = step[this.answerKey] || undefined;
      }
    }
    this.setValues();
  },
  methods: {
    check(catalog: any) {
      if (this.selectedCatalogCheck.includes(catalog)) {
        this.selectedCatalog = _.filter(this.selectedCatalog, function(
          num: any
        ) {
          return num != catalog;
        });
        this.selectedCatalogCheck = this.selectedCatalog;
      } else {
        this.selectedCatalogCheck = this.selectedCatalog;
      }
      this.setValues();
    },
    setValues() {
      if (this.catalog)
        this.$emit("getValue", {
          value: this.selectedCatalog.length > 0 ? this.selectedCatalog : "",
          valid: this.isRequired
            ? this.selectedCatalog.length > 0
              ? true
              : false
            : true,
          answerKey: this.answerKey,
        });
      else
        this.$emit("getValue", {
          value: this.v$.name.$model,
          valid: !(!this.v$.name.$model && this.isRequired),
          answerKey: this.answerKey,
        });
    },
  },
});
